import { SunIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  IconButton,
  Image,
  Input,
  Link,
  SimpleGrid,
  Text,
  useColorMode,
  useToast,
  useToken,
  VStack,
} from '@chakra-ui/react';
import { loginSchema, resetPasswordSchema } from '@wander-app/shared-web';
import { observer, useLocalObservable } from 'mobx-react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { IoMoonOutline } from 'react-icons/io5';
import styled from 'styled-components';
import AuthWrapper from '../../components/AuthWrapper';
import { Form } from '../../components/Form';
import { useMediaQueries, useStore } from '../../hooks';
import { sendResetPasswordEmail } from '../../util/api';
import { AiFillFacebook } from 'react-icons/ai';
import { BsApple } from 'react-icons/bs';

const Index = observer(() => {
  const router = useRouter();
  const store = useStore();
  const { colorMode, toggleColorMode } = useColorMode();
  const [wanderBlack, wanderGray, wanderGrayLight, wanderWhite] = useToken(
    'colors',
    ['wander.black', 'wander.gray', 'wander.grayLight', 'wander.white'],
  );
  const toast = useToast();
  const device = useMediaQueries();

  const state = useLocalObservable(() => ({
    resetPassword: false,
    setResetPassword: (value) => (state.resetPassword = value),
    resetPasswordError: '',
    setResetPasswordError: (value) => (state.resetPasswordError = value),
    passwordResetSuccess: false,
    setPasswordResetSuccess: (value) => (state.passwordResetSuccess = value),
  }));

  useEffect(() => {
    if (store.user.authStatus === 'logged-in') {
      router.replace('/home');
    }
  }, [store.user.authStatus]);

  const AUTH_ERRORS = {
    'auth/wrong-password':
      'Invalid password or a password does not exist for this account. Did you sign up with a social provider?',
    'no-publisher-access':
      "It looks like you haven't been given publisher access yet, send us an email at info@wandermaps.com and we'll let you in ASAP!",
    default: 'Error logging in. Please try again.',
  };

  const logout = async (err) => {
    const errMsg =
      err.response?.data?.message ||
      AUTH_ERRORS[err.code] ||
      AUTH_ERRORS.default;
    toast({
      title: errMsg,
      position: 'top-right',
      status: 'error',
      isClosable: true,
    });
  };

  const onLogin = (provider) => async (data) => {
    const { email, password } = data;
    try {
      await store.user.login({ provider, email, password });
    } catch (err) {
      await logout(err);
      return;
    }
  };

  const onResetPassword = async (data) => {
    state.setPasswordResetSuccess(false);
    state.setResetPasswordError('');
    const { email } = data;
    try {
      await sendResetPasswordEmail(email);
      state.setPasswordResetSuccess(true);
    } catch (err) {
      if (
        err.response?.data?.message === 'A user with that email does not exist.'
      ) {
        return state.setResetPasswordError(
          `No account was found associated with the email ${email}.`,
        );
      } else if (
        err.response?.data?.message === 'ThrottlerException: Too Many Requests'
      ) {
        state.setResetPasswordError(
          `Too many requests. Please try again in a few minutes.`,
        );
      } else {
        state.setResetPasswordError(`An error occurred please try again.`);
      }
    }
  };

  const getBackground = () => {
    const date = new Date();
    const day = date.getDay();
    return [
      'pexels-deva-darshan-1064129.jpg',
      'pexels-invisiblepower-351448.jpg',
      'pexels-joshua-welch-1624600.jpg',
      'pexels-pok-rie-1045844.jpg',
      'pexels-deva-darshan-1064129.jpg',
      'pexels-invisiblepower-351448.jpg',
      'pexels-joshua-welch-1624600.jpg',
    ][day];
  };

  const outerBoxStyles = {
    boxSize: 'full',
    background: `url(/${getBackground()}) center/cover no-repeat`,
    height: '100vh',
  };

  const innerBoxStyles = {
    display: 'flex',
    justifyContent: 'space-around',
    textAlign: 'left',
    width: device.isMobile ? '100%' : null, //device.isDesktop ? '40%' : '50%',
    height: 'full',
    color: 'black',
    flexDirection: 'column',
  };

  const LoginForm = ({ register, formState: { isSubmitting, errors } }) => {
    return (
      <Box mb="6">
        <Text
          fontSize="18px"
          mb="2"
          fontFamily={'Gotham Black'}
          color={colorMode === 'light' ? 'wander.brown' : 'wander.beige'}>
          Sign in
        </Text>
        {store.user.loginError && (
          <Text fontSize="16px" mb="2" style={{ color: 'red' }}>
            Error: {store.user.loginError}
          </Text>
        )}
        <SimpleGrid columns={[1, null, 1]} spacing="15px">
          <HStack alignItems={'flex-start'}>
            <VStack>
              <FormControl isInvalid={!!errors.email}>
                <Input
                  variant={'brand'}
                  id="email"
                  placeholder="Email Address"
                  type={'email'}
                  {...register('email')}
                />
              </FormControl>
              <Button
                variant={'brand'}
                boxShadow="1px 3px 2px rgba(0, 0, 0, 0.15)"
                width={'full'}
                type="submit"
                isLoading={isSubmitting}>
                Submit
              </Button>
            </VStack>
            <VStack>
              <FormControl isInvalid={!!errors.password}>
                <Input
                  variant={'brand'}
                  id="password"
                  placeholder="Password"
                  type={'password'}
                  {...register('password')}
                />
              </FormControl>
              <Link
                fontSize="12px"
                fontWeight="normal"
                color={colorMode === 'light' ? 'wander.brown' : 'wander.beige'}>
                Forget your password?{' '}
                <Button
                  variant={'link'}
                  fontWeight="bold"
                  size={'m'}
                  color={'wander.orange'}
                  onClick={() => {
                    state.setResetPassword(true);
                    state.setPasswordResetSuccess(false);
                    state.setResetPasswordError('');
                  }}>
                  Reset it here.
                </Button>
              </Link>
            </VStack>
          </HStack>
          <HStack></HStack>
        </SimpleGrid>
      </Box>
    );
  };

  const ResetPasswordForm = ({
    register,
    formState: { isSubmitting, errors },
  }) => {
    return (
      <Box mb="6">
        <Text
          fontSize="24px"
          fontWeight="600"
          mb="2"
          color={colorMode === 'light' ? 'wander.brown' : 'wander.beige'}>
          Reset Password
        </Text>
        {state.passwordResetSuccess ? (
          <>
            <Text
              fontSize="16px"
              fontWeight="600"
              mb="2"
              color={colorMode === 'light' ? 'wander.brown' : 'wander.beige'}>
              We have sent you an email with reset instructions. Please check
              your inbox.
            </Text>
            <Link
              fontSize="14px"
              fontWeight="normal"
              color={colorMode === 'light' ? 'wander.brown' : 'wander.beige'}>
              Reset your password?{' '}
              <Button
                variant={'link'}
                fontWeight="bold"
                color={'wander.green'}
                size={'m'}
                onClick={() => {
                  state.setResetPassword(false);
                  state.setPasswordResetSuccess(false);
                  state.setResetPasswordError('');
                }}>
                Log in.
              </Button>
            </Link>
          </>
        ) : (
          <>
            <Text
              fontSize="16px"
              fontWeight="600"
              mb="2"
              color={colorMode === 'light' ? 'wander.brown' : 'wander.beige'}>
              Enter the email address associated with your account, and you'll
              receive a link to reset your password.
            </Text>
            {!!state.resetPasswordError && (
              <Text
                style={{ color: 'red' }}
                fontSize="16px"
                fontWeight="600"
                mb={'2'}>
                {state.resetPasswordError}
              </Text>
            )}
            <SimpleGrid maxWidth="90%" columns={[1, null, 1]} spacing="15px">
              <FormControl isInvalid={!!errors.email}>
                <Input
                  variant={'brand'}
                  id="email"
                  placeholder="Email Address"
                  type={'email'}
                  {...register('email')}
                />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>
              <Link
                fontSize="14px"
                fontWeight="normal"
                color={colorMode === 'light' ? 'wander.brown' : 'wander.beige'}>
                Remember your password?{' '}
                <Button
                  variant={'link'}
                  fontWeight="bold"
                  color={'wander.orange'}
                  size={'m'}
                  onClick={() => state.setResetPassword(false)}>
                  Log in.
                </Button>
              </Link>
              <Button
                variant={'brand'}
                boxShadow="1px 3px 2px rgba(0, 0, 0, 0.15)"
                type="submit"
                isLoading={isSubmitting}>
                Submit
              </Button>
            </SimpleGrid>
          </>
        )}
      </Box>
    );
  };

  return (
    <Flex justifyContent="flex-end" sx={outerBoxStyles}>
      <Box
        sx={innerBoxStyles}
        backgroundImage={
          colorMode === 'light'
            ? 'url("LightModeBkgrnd.svg")'
            : 'url("DarkModeBkgrnd.svg")'
        }
        backgroundSize="cover"
        px={device.isMobile ? '5' : '14'}>
        <Image
          alignSelf={'flex-start'}
          src={
            colorMode === 'light'
              ? '/Wander Logos_Secondary-01.svg'
              : '/Wander Logos_Secondary-02.svg'
          }
          alt="Wander logo"
          h="75px"
        />
        <Box maxWidth={'650px'}>
          <Text
            fontSize="54px"
            fontWeight="700"
            color={colorMode === 'light' ? 'wander.brown' : 'wander.beige'}
            fontFamily={'Crimson Pro'}>
            Welcome to Wander
          </Text>
          <Text
            fontSize="25px"
            mb="40px"
            color={colorMode === 'light' ? 'wander.brown' : 'wander.beige'}>
            A world-class map building experience.
          </Text>
          <Box>
            {state.resetPassword ? (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <Form
                onSubmit={onResetPassword}
                children={ResetPasswordForm}
                schema={resetPasswordSchema}
                id={'ResetPassword'}
                defaultValues={{
                  email: '',
                }}
              />
            ) : (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <Form
                onSubmit={onLogin('email')}
                children={LoginForm}
                schema={loginSchema}
                id={'Login'}
                defaultValues={{
                  email: '',
                  password: '',
                }}
              />
            )}

            <Text
              fontSize="18px"
              mb="2"
              mt="40px"
              fontFamily={'Gotham Black'}
              color={colorMode === 'light' ? 'wander.brown' : 'wander.beige'}>
              Sign in with
            </Text>
            <IconButton
              aria-label="Login with google"
              variant="secondary"
              size="lg"
              isRound={true}
              boxShadow="1px 3px 2px rgba(0, 0, 0, 0.15)"
              onClick={onLogin('google')}
              icon={<FcGoogle />}
            />
            <IconButton
              ml={2}
              aria-label="Login with google"
              variant="secondary"
              color={'blue'}
              size="lg"
              isRound={true}
              boxShadow="1px 3px 2px rgba(0, 0, 0, 0.15)"
              onClick={onLogin('facebook')}
              icon={<AiFillFacebook />}
            />
            <IconButton
              ml={2}
              aria-label="Login with google"
              variant="secondary"
              size="lg"
              isRound={true}
              color={'grey'}
              boxShadow="1px 3px 2px rgba(0, 0, 0, 0.15)"
              onClick={onLogin('apple')}
              icon={<BsApple />}
            />
            <Flex mt="100px">
              <StyledColorModeSelector
                colorMode={colorMode}
                darkBgColor={wanderGrayLight}>
                <div className="dark-mode__toggle" onClick={toggleColorMode}>
                  <div
                    className="toggle"
                    style={{
                      backgroundColor:
                        colorMode === 'dark' ? wanderWhite : wanderGray,
                    }}>
                    {colorMode === 'dark' ? (
                      <IoMoonOutline
                        color={wanderGrayLight}
                        style={{ margin: '0 auto' }}
                      />
                    ) : (
                      <SunIcon
                        color={wanderWhite}
                        style={{ margin: '0 auto' }}
                      />
                    )}
                  </div>
                  <Text
                    fontSize="13px"
                    fontWeight="bold"
                    ml={colorMode === 'dark' ? '39px' : '12px'}
                    color={
                      colorMode === 'dark' ? 'white' : 'wander.grayishDark'
                    }>
                    {colorMode === 'dark' ? 'Dark Mode' : 'Light Mode'}
                  </Text>
                </div>
              </StyledColorModeSelector>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
});

const StyledColorModeSelector = styled.div<{
  colorMode: string;
  darkBgColor: string;
}>`
  .dark-mode__toggle {
    background: ${({ colorMode, darkBgColor }) =>
      colorMode === 'dark' ? darkBgColor : 'white'};
    border-radius: 46px;
    height: 32px;
    position: relative;
    width: ${({ colorMode }) => (colorMode === 'dark' ? '122px' : '125px')};
    cursor: pointer;
    margin: 0 auto;
    display: flex;
    align-items: center;
    box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.15);
  }
  .toggle {
    color: white;
    left: ${({ colorMode }) => (colorMode === 'dark' ? '3px' : '95px')};
    position: absolute;
    transition: 0.2s;
    display: flex;
    align-items: center;
    background: ${({ colorMode }) =>
      colorMode === 'dark' ? 'white' : 'wander.grayishDark'};
    border-radius: 50%;
    width: 28px;
    height: 28px;
  }
`;

export default () => (
  <AuthWrapper>
    <Index />
  </AuthWrapper>
);
