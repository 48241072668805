import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../util/firebase';
import { useStore } from '../hooks';
import {
  refreshCookie,
  refreshCookieComplete$,
  refreshCookieInProgress$,
} from '../util/api';
import * as _ from 'lodash/fp';
import { useUserData } from '../hooks';
import { useQueryClient } from 'react-query';

const AuthWrapper = observer(({ children, noNull = false }) => {
  const store = useStore();
  const queryClient = useQueryClient();
  const [user, loading, error] = useAuthState(auth, {
    onUserChanged: async (user) => {
      if (user) {
        let userClaims;
        try {
          userClaims = _.pick(
            ['publisher', 'GnRqJv7bPUYyNscs17GLj'],
            JSON.parse(_.get('reloadUserInfo.customAttributes', user)),
          );
        } catch (_err) {
          userClaims = {};
        }

        let { claims: updatedClaims } = await auth.currentUser.getIdTokenResult(
          true,
        );

        updatedClaims = _.pick(
          ['publisher', 'GnRqJv7bPUYyNscs17GLj'],
          updatedClaims,
        );

        const equalClaims = _.isEqual(userClaims, updatedClaims);
        if (!equalClaims) {
          _.delay(5000, async () => {
            await store.user.updateClaims();
          });
        }
      } else {
        store.user.logout();
      }
    },
  });
  const { userData } = useUserData(store.user?.claims?.publisher && user);

  useEffect(() => {
    if (userData?.plan) {
      store.user.setPlan(userData.plan);
    }
  }, [userData]);

  useEffect(() => {
    const inProgress = refreshCookieInProgress$.subscribe({
      next: () => {
        store.user.setIsRefreshingToken(true);
      },
    });
    const complete = refreshCookieComplete$.subscribe({
      next: () => {
        queryClient
          .invalidateQueries()
          .then(_.noop)
          .finally(() => {
            store.user.setIsRefreshingToken(false);
          });
      },
    });

    return () => {
      inProgress.unsubscribe();
      complete.unsubscribe();
    };
  }, [queryClient]);

  useEffect(() => {
    async function check() {
      // stop the page from blinking and doing thins again if already authed
      if (store.user.authStatus === 'logged-in' || loading) return;
      store.user.setAuthStatus('idle');
      if (user) {
        try {
          await refreshCookie();
          await store.user.updateClaims();
          await store.user.updateRoles();
          store.user.setAuthStatus('logged-in');
          return;
        } catch (err) {
          return store.user.logout();
        }
      }
      if (error) {
        store.user.logout();
      }
      store.user.setAuthStatus('logged-out');
    }
    check();
  }, [user, loading, error]);

  if (!noNull && (store.user.authStatus === 'idle' || loading)) {
    return null;
  }
  if (error) {
    return (
      <div>
        <p>Error: {error}</p>
      </div>
    );
  }
  return children;
});

export default AuthWrapper;
